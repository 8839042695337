import { useState } from 'react'
import { Input } from 'component-library'
import useForm from '../../hooks/useForm'
import researchContent from '../../content/research'
import FormLayout from '../FormLayouts/FormLayout/FormLayout'

/* Form State */
type FormValues = {
  firstName: string
  lastName: string
  email: string
  query: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  query: '',
}

const GOOGLE_SHEET_URL = `https://script.google.com/a/macros/xanadu.ai/s/AKfycbw7IREhWMLqRV_MkQDEvTzaOm3zx4UIb0CoTQDAALeMhMpfyDxbROVSjxotqOaLCLN60w/exec`
const FORM_IMAGE =
  'https://assets.cloud.pennylane.ai/pennylane_website/pages/research/feature_form.png'
const FORM_NAME = 'research-contact-form'

const { submitForm } = researchContent

export default function ResearchContact({ pageName }: { pageName?: string }) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false)

  const { values, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues,
  })

  const submitEducationForm = async (values: FormValues) => {
    const formData = new FormData()
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('email', values.email)
    formData.append('query', values.query)
    formData.append('page', pageName || 'NA')

    setShowFormSubmissionError(false)
    setSubmittingForm(true)

    try {
      const response = await fetch(GOOGLE_SHEET_URL, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        setIsSubmitted(true)
      } else {
        setShowFormSubmissionError(true)
      }
    } catch (error) {
      setShowFormSubmissionError(true)
    }

    setSubmittingForm(false)
  }

  return (
    <FormLayout
      id="get-in-touch"
      className="ResearchContact"
      title={submitForm.title}
      description={submitForm.description}
      footnoteMarkdown={submitForm.footnote}
      image={FORM_IMAGE}
      formName={FORM_NAME}
      onSubmit={handleSubmit(submitEducationForm)}
      isSubmitted={isSubmitted}
      isSubmitting={submittingForm}
      isSubmissionError={showFormSubmissionError}
      submissionErrorText={submitForm.formErrorMessage}
      formSuccessTitle={submitForm.formSubmittedTitle}
      formSuccessText={submitForm.formSubmittedText}
    >
      <div className="grid grid-cols-2 gap-2 w-full">
        <div className="grid md:grid-cols-2 gap-2 md:gap-3 col-span-2">
          <Input
            className="w-full"
            fieldLabelHidden
            required
            formName={FORM_NAME}
            disabled={submittingForm}
            fieldName="firstName"
            placeholder="First name"
            fieldLabel="First name"
            updateValue={(value) => handleChange('firstName', value)}
            value={values?.firstName || ''}
            maxLength={25}
          />
          <Input
            className="w-full"
            fieldLabelHidden
            required
            formName={FORM_NAME}
            disabled={submittingForm}
            fieldName="lastName"
            placeholder="Last name"
            fieldLabel="Last name"
            updateValue={(value) => handleChange('lastName', value)}
            value={values?.lastName || ''}
            maxLength={25}
          />
        </div>
        <Input
          className="w-full col-span-2"
          fieldLabelHidden
          type="email"
          required
          formName={FORM_NAME}
          disabled={submittingForm}
          fieldName="email"
          placeholder="Email"
          fieldLabel="Email"
          pattern={`[^@\\s]+@[^@\\s]+\\.[^@\\s]+`}
          updateValue={(value) => handleChange('email', value)}
          value={values?.email || ''}
        />
        <Input
          className="w-full col-span-2"
          fieldLabelHidden
          type="textarea"
          required
          formName={FORM_NAME}
          disabled={submittingForm}
          fieldName="query"
          placeholder="Tell us about your research needs!"
          fieldLabel="Query"
          updateValue={(value) => handleChange('query', value)}
          value={values?.query || ''}
          maxLength={800}
        />
      </div>
    </FormLayout>
  )
}
